import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import HomeLugansk from "../views/HomeLugansk.vue";
import Drivers from "@/views/Drivers.vue";
import PublicOffer from "@/views/PublicOffer.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import store from "@/store";
import PrivacyPolicyEn from "@/views/PrivacyPolicyEn.vue";
import PublicOfferEn from "@/views/PublicOfferEn.vue";
import PublicOfferBn from "@/views/PublicOfferBn.vue";
import PublicOffertAuto from "@/views/PublicOffertAuto.vue";
import PrivacyPolicyBn from "@/views/PrivacyPolicyBn.vue";
import CallbackForm from "@/views/CallbackForm.vue";
import LegalInformation from "@/views/LegalInformation.vue";
import PrivacyPolicyClient from "@/views/PrivacyPolicyClient.vue";
import LicenseAgreement from "@/views/LicenseAgreement.vue";
import LicenseAgreementAuto from "@/views/LicenseAgreementAuto.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/lugansk",
    name: "HomeLugansk",
    component: HomeLugansk,
    props: store.state.isLugansk,
  },
  {
    path: "/driver",
    name: "Driver",
    component: Drivers,
  },
  {
    path: "/lugansk/driver",
    name: "Driver",
    component: Drivers,
    props: store.state.isLugansk,
  },
  {
    path: "/publicoffer",
    redirect: (to) => {
      const language = store.state.currentLanguage.id;
      return { path: `/publicoffer/${language}` };
    },
  },
  {
    path: "/legal-information",
    name: "Legal Information",
    component: LegalInformation,
  },
  {
    path: "/publicoffer/ru",
    name: "Public Offer",
    component: PublicOffer,
  },
  {
    path: "/publicoffer-auto/ru",
    name: "Public Offer Auto",
    component: PublicOffertAuto,
  },
  {
    path: "/publicofferclient/ru",
    name: "Public Offer Client",
    component: PrivacyPolicyClient,
  },
  {
    path: "/legal-information",
    name: "Legal Information",
    component: LegalInformation,
  },
  {
    path: "/licence-agreement",
    name: "License Agreement",
    component: LicenseAgreement,
  },
  {
    path: "/licence-agreement-auto",
    name: "License Agreement Auto",
    component: LicenseAgreementAuto,
  },
  {
    path: "/privacy-policy/ru",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/privacy-policy-client",
    name: "Privacy Policy Client",
    component: PrivacyPolicyClient,
  },
  {
    path: "/publicoffer/en",
    name: "Public Offer",
    component: PublicOfferEn,
  },
  {
    path: "/privacy-policy/en",
    name: "Privacy Policy",
    component: PrivacyPolicyEn,
  },
  {
    path: "/publicoffer/bn",
    name: "Public Offer",
    component: PublicOfferBn,
  },
  {
    path: "/privacy-policy/bn",
    name: "Privacy Policy",
    component: PrivacyPolicyBn,
  },
  {
    path: "/callback",
    name: "Callback form",
    component: CallbackForm,
  },
  {
    path: "/privacy-policy",
    redirect: (to) => {
      const language = store.state.currentLanguage.id;
      return { path: `/privacy-policy/${language}` };
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
